import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Hero({ title, student, image, crop }) {
  return (
    <div className="hero">
      <GatsbyImage
        style={{
          position: "absolute",
          left: "0",
          top: "0",
        }}
        transformOptions={{ fit: "cover", cropFocus: "top" }}
        objectFit="cover"
        objectPosition={crop ? crop : "top"}
        width={1500}
        className="heroImage"
        image={image}
        alt={title ? title : `Work of ${student}`}
      />
    </div>
  );
}
