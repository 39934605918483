/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import Menu from "../components/Menu";
import { useState, useEffect } from "react";

import "./layout.css";

const Layout = ({ children, shouldIShowMenu }) => {
  //below is stuff that was here before
  const [HasScrolledDown, setHasScrolledDown] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    if (shouldIShowMenu === false) {
      setShowMenu(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let scrolled = window.scrollY;
      if (scrolled > 100) {
        setHasScrolledDown(true);
      } else setHasScrolledDown(false);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [HasScrolledDown]);

  return (
    <>
      {showMenu && <Menu hasScrolledDown={HasScrolledDown} />}
      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
