import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

// Utilities
import kebabCase from "lodash/kebabCase";

export default function NextStudent({ currentStudent, collection }) {
  let placeholder;

  if (!collection.student[0].projectName) {
    placeholder = "Student";
  } else {
    placeholder = collection.student[0].projectName;
  }

  const [nextStudent, setNextStudent] = useState(placeholder);

  const [prevStudent, setPrevStudent] = useState(placeholder);

  useEffect(() => {
    runNextStudent();
  }, []);

  function runNextStudent() {
    //find what number the current student is in the index
    let currentStudentIndex = collection.student.findIndex(function (student) {
      if (student.slug === currentStudent) return true;
      else {
        return false;
      }
    });

    //Now, if we have successfully found the student (-1 is returned if we fail)
    if (currentStudentIndex >= 0) {
      //if the index is at the end of the collection, then return 0 to loop back to start
      if (currentStudentIndex >= collection.student.length - 1) {
        setNextStudent(collection.student[0]);
        setPrevStudent(collection.student[currentStudentIndex - 1]);
      } else if (currentStudentIndex === 0) {
        setNextStudent(collection.student[currentStudentIndex + 1]);
        setPrevStudent(collection.student[collection.student.length - 1]);
      } else {
        //set nextStudent to the next student
        setNextStudent(collection.student[currentStudentIndex + 1]);
        setPrevStudent(collection.student[currentStudentIndex - 1]);
      }
    }
  }

  return (
    <>
      {collection == null && (
        <div className="nextStudent">
          <Link className="nextStudentCollectionTitle" to={`/exhibition/}`}>
            Back to exhibition
          </Link>
        </div>
      )}
      {collection !== null && (
        <>
          <div className="nextStudent mobile">
            <div className="nextStudentTitle mobile">
              <Link
                className="nextStudentCollectionTitle"
                to={`/exhibition/${kebabCase(collection.title)}`}
              >
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="4" height="4" rx="1" />
                  <rect y="5" width="4" height="4" rx="1" />
                  <rect x="5" width="4" height="4" rx="1" />
                  <rect x="5" y="5" width="4" height="4" rx="1" />
                </svg>

                {collection.title}
              </Link>
            </div>
            <div className="nextStudentLeftRight mobile">
              <Link
                className="nextStudentLeft"
                to={`/students/${prevStudent.slug}`}
              >
                <svg
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4.54167 8L1 4.375M1 4.375L4.54167 0.75M1 4.375L11 4.375" />
                </svg>

                {prevStudent.projectName}
              </Link>
              <Link
                className="nextStudentRight"
                to={`/students/${nextStudent.slug}`}
              >
                {nextStudent.projectName}
                <svg
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.45833 1L10 4.625M10 4.625L6.45833 8.25M10 4.625L-1.58454e-07 4.625" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="nextStudent desktop">
            <Link
              className="nextStudentLeft"
              to={`/students/${prevStudent.slug}`}
            >
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.66667 12.5996L1 6.79961M1 6.79961L6.66667 0.999609M1 6.79961L17 6.79961" />
              </svg>
              {prevStudent.projectName}
            </Link>
            <Link
              className="nextStudentCollectionTitle"
              to={`/exhibition/${kebabCase(collection.title)}`}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="6" height="6" rx="1" />
                <rect y="7" width="6" height="6" rx="1" />
                <rect x="7" width="6" height="6" rx="1" />
                <rect x="7" y="7" width="6" height="6" rx="1" />
              </svg>

              {collection.title}
            </Link>
            <Link
              className="nextStudentRight"
              to={`/students/${nextStudent.slug}`}
            >
              {nextStudent.projectName}
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.3333 1L16 6.8M16 6.8L10.3333 12.6M16 6.8L-2.53526e-07 6.8" />
              </svg>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
