import React from "react";
// import { PauseCircleFilled } from "@material-ui/icons";

export default function Play(props) {
  const { handleClick, isLoading } = props;

  let loading = (
    <div class="snippet" data-title=".dot-falling">
      <div class="stage">
        <div class="dot-flashing"></div>
      </div>
    </div>
  );
  return (
    <button className="player__button" onClick={() => handleClick()}>
      {/* <PauseCircleFilled /> */}
      {isLoading ? loading : "Pause"}
    </button>
  );
}
