import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

//Just whilst testing - static image
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Hero from "../components/Hero";

import NextStudent from "../components/NextStudent";
import Audio from "../components/audioplayer/Audio";

export default function Template({ data, pageContext }) {
  const post = data.contentfulStudent;
  let otherAssetsMapped, behindTheScenes, behindTheScenesImages;

  if (post.behindTheScenesImages) {
    behindTheScenesImages = post.behindTheScenesImages.map(function (
      element,
      index
    ) {
      return (
        <GatsbyImage
          className={
            "processImage p-i" + post.behindTheScenesImages.length + index
          }
          backgroundColor="#eeeeee"
          objectFit="cover"
          key={element.id}
          // layout="constrained"
          image={getImage(element)}
          alt={
            element.title
              ? element.title
              : `Behind the scenes of ${post.studentName}`
          }
        />
      );
    });
  }

  if (post.behindTheScenesImages) {
    behindTheScenes = (
      <div className="slide process processContainer">
        <h3 className="processTitle">Process</h3>

        {behindTheScenesImages}
      </div>
    );
  }

  if (post.otherAssets) {
    otherAssetsMapped = post.otherAssets.map(function (element) {
      if (element.file.contentType.includes("video")) {
        return (
          <div key={element.id} className="slide tall">
            <video className="studentAsset" controls>
              <source src={element.file.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      } else if (element.file.contentType.includes("audio")) {
        return (
          <div key={element.id} className="slide tall">
            <Audio src={element.file.url} />
          </div>
        );
      } else if (element.file.contentType.includes("text")) {
        return (
          <div key={element.id} className="slide tall">
            <iframe
              className="studentAssetVimeo"
              src={"https://player.vimeo.com/video/" + element.title}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        );
      } else
        return (
          <div key={element.id} className="slide tall">
            <GatsbyImage
              className="studentAsset"
              objectFit="contain"
              key={element.id}
              image={getImage(element)}
              alt={
                element.title ? element.title : `Work of ${post.studentName}`
              }
            />
          </div>
        );
    });
  }

  let collectionForNextStudent;

  if (!post.collection) {
    collectionForNextStudent = data.contentfulCollection;
  } else {
    collectionForNextStudent = post.collection[0];
  }

  return (
    <Layout>
      <Seo title={`${post.studentName} • KSA Foundation 2021`} />
      {/* Show the hero image at the top */}
      <main className="student-main">
        {post.heroImage && (
          <Hero
            title={post.heroImage ? post.heroImage.title : null}
            image={post.heroImage ? getImage(post.heroImage) : null}
            student={post.studentName}
            crop={post.crop}
          />
        )}
        <div className="projectCaption slide">
          <div className="titleBar">
            <h1>{post.projectName} </h1>
            <p>{post.studentName}</p>
          </div>
          {/* If there's a description, show it */}
          {post.projectDescription && (
            <p className="projectDescription">
              {post.projectDescription.projectDescription}
            </p>
          )}
        </div>
        {/* If there are no assets, this shows up as null. If there are other assets, they show up here */}
        {otherAssetsMapped}

        {behindTheScenes}
      </main>

      <NextStudent
        currentStudent={post.slug}
        collection={collectionForNextStudent}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulCollection {
      id
      student {
        studentName
        projectName
        slug
      }
      title
    }
    contentfulStudent(slug: { eq: $slug }) {
      studentName
      specialism
      crop
      projectName
      projectDescription {
        projectDescription
      }
      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
        id
        title
      }
      otherAssets {
        id
        file {
          contentType
          fileName
          url
        }
        gatsbyImageData
        id
        title
      }
      slug
      collection {
        title
        student {
          studentName
          projectName
          heroImage {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
            id
            title
          }
          slug
        }
      }
      behindTheScenesImages {
        gatsbyImageData(aspectRatio: 1.5)
        id
        title
      }
    }
  }
`;
