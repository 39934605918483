import React, { useState, useEffect, useRef } from "react";

// import Song from "./Song";
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";

import useAudioPlayer from "./UseAudioPlayer";

function Audio({ src, time, clicked, setCurTime }) {
  const {
    curTime,
    duration,
    playing,
    loading,
    setPlaying,
    setClickedTime,
    setLoading,
  } = useAudioPlayer();

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setClickedTime(time);
    setPlaying(true);
  }, [clicked]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    setCurTime(curTime);
  }, [curTime]);

  function handleClick(needtoload) {
    setPlaying(!playing);
  }

  function readyToPlay() {
    setLoading(false);
  }

  return (
    <div className="player">
      <audio onCanPlayThrough={() => readyToPlay()} id="audio" preload="auto">
        <source src={src} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        {playing ? (
          <Pause isLoading={loading} handleClick={() => handleClick()} />
        ) : (
          <Play isLoading={loading} handleClick={() => handleClick()} />
        )}
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
      </div>
    </div>
  );
}

export default Audio;
