import React, { useState } from "react";
import { Link } from "gatsby";

export default function Menu(props) {
  //grab width using util

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  let HasScrolledDown = props.hasScrolledDown;

  return (
    <>
      <nav
        className={
          "menu " +
          (menuIsOpen ? "menuOpen " : "menuShut ") +
          (HasScrolledDown ? "menuHidden" : "menuShow")
        }
      >
        <div className="menuMobile">
          <Link className="menuLink menuTitle" to="/">
            <p>KSA Foundation 2021</p>
          </Link>
          <button
            style={{ display: "inline-block" }}
            className="menuButton"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            Menu
          </button>
        </div>
        <Link
          className="menuLink"
          activeClassName="menuActive"
          to={`/exhibition/`}
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent
              ? { className: "menuLink menuActive" }
              : { className: "menuLink" };
          }}
        >
          <p>Exhibition</p>
        </Link>
        <Link className="menuLink" activeClassName="menuActive" to={`/cinema/`}>
          <p>Cinema</p>
        </Link>

        <Link
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent
              ? { className: "menuLink menuActive" }
              : { className: "menuLink" };
          }}
          className="menuLink"
          activeClassName="menuActive"
          to={`/radio/`}
        >
          <p>Radio</p>
        </Link>

        <Link
          className="menuLink"
          activeClassName="menuActive"
          to={`/all`}
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent
              ? { className: "menuLink menuActive" }
              : { className: "menuLink" };
          }}
        >
          <p>Index</p>
        </Link>
      </nav>
    </>
  );
}
